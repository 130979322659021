import React from 'react'
import styled from 'styled-components'

import { Heading3, Paragraph } from '@components/Typography'

type AuthorComponentProps = {
  style: 'one' | 'two'
  name: string
  title: string
  image: {
    src: string
    srcSet?: string
    alt: string
  }
}

const AuthorComponent: React.FC<AuthorComponentProps> = props => {
  return (
    <AuthorWrapper type={props.style}>
      <AuthorContent type={props.style}>
        <Heading3 centered={props.style === 'two'} as='p'>
          Written by {props.style === 'one' ? <br /> : null} {props.name}
        </Heading3>
        <Paragraph centered={props.style === 'two'} size='sm'>
          {props.title}
        </Paragraph>
      </AuthorContent>
      <Author {...props.image} type={props.style} />
    </AuthorWrapper>
  )
}

export default AuthorComponent

const AuthorWrapper = styled.div<{ type: 'one' | 'two' }>`
  width: 100%;
  display: flex;
  gap: 1rem;
  justify-content: ${({ type }) =>
    type === 'one' ? 'space-between' : 'center'};
  align-items: center;
  flex-direction: ${({ type }) => (type === 'one' ? 'row' : 'column')};
`
const AuthorContent = styled.div<{ type: 'one' | 'two' }>`
  order: ${({ type }) => (type === 'one' ? 1 : 2)};
`

const Author = styled.img<{ type: 'one' | 'two' }>`
  height: 6.25rem;
  width: 6.25rem;
  border-radius: 6.25rem;
  order: ${({ type }) => (type === 'one' ? 2 : 1)};
`
