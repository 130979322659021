import React from 'react'
import styled, { ThemeProvider, css } from 'styled-components/macro'
import { graphql } from 'gatsby'

// Components
import SeoComponent from '@components/SeoComponent/SeoComponent'
import FullImageHeroComponent from '@components/FullImageHeroComponent'
import AuthorComponent from '@components/AuthorComponent'
import BlogListingComponent from '@components/BlogListingComponent'
import { VerticalHeading } from '@components/Typography'
import SpacerComponent from '@components/SpacerComponent'
import { ContentComponent } from '@components/MdxComponents'
import SocialShareIconsComponent from '@components/SocialShareIconsComponent'
import SectionComponent from '@components/SectionComponent'
import { FadeIn } from '@components/Animations'
import Grid from '@components/ViewGridComponent'

import { paleBlueTheme } from '../themes'

import { GetBlogPost } from '../API'

type BlogPostProps = {
  data: GetBlogPost
  path: string
  uri: string
  params: object
  pageResources: any
  navigate: any
  pageContext: {
    slug: string
  }
}

const BlogPost: React.FC<BlogPostProps> = props => {
  const post = props.data.contentfulBlog
  const blogPosts = props.data.allContentfulBlog

  const author = {
    name: post.author.name,
    title: post.author.title,
    image: {
      src: post.author.profile.fixed.src,
      srcSet: post.author.profile.fixed.srcSet,
      alt: post.author.profile.title,
    },
  }

  return (
    <Article>
      <SeoComponent
        title={post.title}
        description={post.intro}
        image={post.image.fixed.src}
        article
      />

      <FullImageHeroComponent
        image={{ ...post.image.fixed, alt: post.image.title }}
        reduceHeroHeight={false}
        removeSectionMinHeight
        heading={{
          title: post.title,
          copy: post.intro,
          readingTime: post.body.childMdx.timeToRead,
        }}
        content={
          <>
            <SpacerComponent top='lg' />
            <Line />
            <SpacerComponent top='sm' bottom='sm'>
              <AuthorComponent {...author} style='one' />
            </SpacerComponent>
            <Line />
            <SocialShareIconsComponent removeMargin={true} />
          </>
        }
        removePadding
      />
      <ContentComponent body={post.body.childMdx.body} />
      <ThemeProvider theme={paleBlueTheme}>
        <SectionComponent>
          <Grid.Item
            column='1 / 1'
            // for each blog there are 3 components
            row={`1 / ${blogPosts.nodes.length * 3}`}
            justifySelfStart
          >
            <FadeIn>
              <VerticalHeading>From the Blog</VerticalHeading>
            </FadeIn>
          </Grid.Item>
          {blogPosts.nodes.map((node, index) => {
            return (
              <BlogListingComponent
                hasBottomSpacer={index + 1 < blogPosts.nodes.length}
                size={index === 0 ? 'large' : 'small'}
                listing={node}
              />
            )
          })}
        </SectionComponent>
      </ThemeProvider>
    </Article>
  )
}

export default BlogPost

const Article = styled.article`
  padding-top: ${({ theme }) => theme.spacing.xl2};
`
const Line = styled.div`
  width: 100%;
  border-bottom: solid 1px var(--color-text);
  opacity: 0.5;
  margin: ${({ theme }) => theme.spacing.sm} 0;
`

export const pageQuery = graphql`
  query GetBlogPost($slug: String) {
    contentfulBlog(slug: { eq: $slug }) {
      intro
      title
      body {
        childMdx {
          body
          timeToRead
        }
      }
      image {
        fixed(width: 1000, quality: 80) {
          srcSet
          src
        }
        title
      }
      author {
        name
        title
        profile {
          fixed(height: 150, width: 150, cropFocus: FACE) {
            srcSet
            src
          }
          title
        }
      }
    }
    allContentfulBlog(
      limit: 3
      sort: { order: DESC, fields: date }
      filter: { slug: { ne: $slug } }
    ) {
      nodes {
        slug
        title
        date(formatString: "Do MMMM, YYYY")
        image {
          title
          file {
            url
          }
        }
      }
    }
  }
`
